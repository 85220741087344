import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';

import { getAccount } from '../../../database/actions/account'

import '../styles/profile.css'

const Profile = () => {
  const [user, setUser] = useState(JSON.parse(localStorage.getItem('profile')));
  const { account, isLoading } = useSelector((state) => state.accounts);
  const dispatch = useDispatch();

  useEffect(() => {
    if (user) {
      dispatch(getAccount(user?.result?._id));
    }
  }, [dispatch, user])

  return (
    <div className="profile">
      <div className="cont">
        <div className="headt">
          <h2>@{account?.userName}</h2>
          <h3>{account?.accountType} Account</h3>
        </div>
        <div className="prof-dets">
              <h3>Biography</h3>
          <div className="bio-data">
            <div className="img" style={{ "backgroundImage": `url(${account?.id})`}}>
              
            </div>
            <div className="names">
              <h4>First Name: <span>{account?.firstName}</span></h4>
              <h4>Last Name: <span>{account?.lastName}</span></h4>
              <h4>Email: <span>{account?.email}</span></h4>
              <h4>Number: <span>{account?.number}</span></h4>
              <h4>DOB: <span>{moment(account?.dob).format('LL')}</span></h4>
            </div>
          </div>
          <div className="home-address">
            <h3>Address</h3>
            <h4>Home Address <span>{account?.homeAddress}</span></h4>
            <h4>Country <span>{account?.country}</span></h4>
            <h4>Region <span>{account?.region}</span></h4>
            <h4>City <span>{account?.city}</span></h4>
          </div>
          <div className="business-address">
            <h3>Business</h3>
            <h4>Business Name <span>{account?.businessName}</span></h4>
            <h4>Business Type <span>{account?.businessType}</span></h4>
            <h4>Business Activity <span>{account?.businessActivity}</span></h4>
            <h4>Address <span>{account?.businessAddress}</span></h4>
            <h4>Date Of Establishment <span>{account?.businessEst}</span></h4>
            <h4>Total Employees <span>{account?.totalEmployees}</span></h4>
            <h4>Gross Revenue <span>{account?.grossRevenue}</span></h4>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Profile