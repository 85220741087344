import * as api from '../api/account';

export const getAccount = (id) => async (dispatch) => {
  try {
    dispatch({ type: 'START_LOADING'});
      const { data } = await api.fetchAccount(id);

      dispatch({ type: 'FETCH_ACCOUNT', payload: data });
      dispatch({ type: 'STOP_LOADING'});
  } catch (error) {
      console.log(error.message);
  }
}

export const getAccounts = () => async (dispatch) => {
  try {
    dispatch({ type: 'START_LOADING'});
      const { data } = await api.fetchAccounts();

      dispatch({ type: 'FETCH_ACCOUNTS', payload: data });
      dispatch({ type: 'STOP_LOADING'});
  } catch (error) {
      console.log(error.message);
  }
}

export const updateProfile = ( id, allData, navigate) => async (dispatch) => {
  try {
    dispatch({ type: 'START_LOADING'});
    const { data } = await api.updateProfile(id, allData);

    dispatch({ type: 'UPDATEPROFILE', payload: data})
    navigate(`/${allData.userName}/dashboard`);
    dispatch({ type: 'STOP_LOADING'});
  } catch (error) {
    console.log(error);
  }
}

export const updatePassword = ( id, formData, navigate) => async (dispatch) => {
  try {
    dispatch({ type: 'START_LOADING'});
    const { data } = await api.updatePassword(id, formData);

    dispatch({ type: 'UPDATEPASSWORD', payload: data})
    navigate(`/user/dashboard`);
    dispatch({ type: 'STOP_LOADING'});
  } catch (error) {
    console.log(error);
  }
}

export const sendMSG = (msgDets, userID) => async (dispatch) => {
  try {
    dispatch({ type: 'START_SENDING'});
    const {data} = await api.msg(msgDets, userID);
    
    dispatch({ type: 'MSG', payload: data})
    
    dispatch({ type: 'STOP_SENDING'});
    return data.liveChats;
  } catch (error) {
    console.log(error);
  }
}

export const updateMsgCounterSupport = (current, userID) => async (dispatch) => {
  try {
    const {data} = await api.updateMsgCounterSupport(current, userID);
    
    dispatch({ type: 'UPDATE', payload: data})
  } catch (error) {
    console.log(error);
  }
}

export const transfer = ( id, formData, navigate, user) => async (dispatch) => {
  try {
    dispatch({ type: 'START_LOADING'});
    const { data } = await api.transfer(id, formData);

    dispatch({ type: 'TRANSACT', payload: data})
    navigate(`/${user.result.userName}/successPage`);
    dispatch({ type: 'STOP_LOADING'});
  } catch (error) {
    console.log(error);
  }
}

export const withdraw = ( id, formData, navigate, user) => async (dispatch) => {
  try {
    dispatch({ type: 'START_LOADING'});
    const { data } = await api.withdraw(id, formData);

    dispatch({ type: 'TRANSACT', payload: data})
    navigate(`/${user.result.userName}/successPage`);
    dispatch({ type: 'STOP_LOADING'});

  } catch (error) {
    console.log(error);
  }
}


export const requestCard = ( id, formData, navigate, user) => async (dispatch) => {
  try {
    dispatch({ type: 'START_LOADING'});
    const { data } = await api.requestCard(id, formData);

    dispatch({ type: 'TRANSACT', payload: data})
    navigate(`/${user.result.userName}/liveChat`);
    dispatch({ type: 'STOP_LOADING'});
  } catch (error) {
    console.log(error);
  }
}