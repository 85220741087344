import React from 'react'
import { useInView } from 'react-intersection-observer'

import '../styles/eta.css'

import Logo from '../../others/Logo';

import { GoLocation } from "react-icons/go";
import { RiCustomerService2Fill } from "react-icons/ri";
const ETA = () => {
  const { ref: aRef, inView: aView} = useInView();
  const { ref: bRef, inView: bView} = useInView();
  const { ref: cRef, inView: cView} = useInView();
  return (
    <>
      <div className="eta">
        <div className="eta-cont">
          <div className={"foot-nav anihideVis" + (aView ? " anishowVis" : " ")} style={{ "--i" : "4"}} ref={aRef}>
            <Logo />
          </div>
          <div className={"etas anihideVis" + (bView ? " anishowVis" : " ")} style={{ "--i" : "6"}} ref={bRef}>
            <RiCustomerService2Fill />
            <ul>
              <h4>Contact Us</h4>
              <li><p><a href="tel:+1">+1</a></p></li>
              {/* <li><p><a href="mailto:support@firstfranklin.online">support@firstfranklin.online</a></p></li> */}
            </ul>
          </div>
          <div className={"etas anihideVis" + (cView ? " anishowVis" : " ")} style={{ "--i" : "8"}} ref={cRef}>
            <GoLocation />
            <ul>
              <h4>Location</h4>
              <li><p>60610 Illinois, USA</p></li>
              <li><p>1165 N Clark St #200, Chicago</p></li>
            </ul>
          </div>
        </div>
      </div>
    </>
  )
}

export default ETA