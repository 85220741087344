import React from 'react'
import { useInView } from 'react-intersection-observer'

import '../../styles/hotline.css'

import { FaLaptopHouse } from "react-icons/fa";
import { TbMessageStar } from "react-icons/tb";
import { FaPhone } from "react-icons/fa";

const HotLine = () => {
  const { ref: aRef, inView: aView} = useInView();
  const { ref: bRef, inView: bView} = useInView();
  const { ref: cRef, inView: cView} = useInView();
  return (
    <>
      <div className="hotline">
        <div className={"hot-loc anihideVis" + (aView ? " anishowVis" : " ")} style={{ "--i" : "4"}} ref={aRef}>
          <FaLaptopHouse />
          <h5 >Visit us</h5>
          <h3>USA & Mexico</h3>
        </div>
        <div className={"hot-loc anihideVis" + (cView ? " anishowVis" : " ")} style={{ "--i" : "8"}} ref={cRef}>
          <FaPhone />
          <h5>hotLine</h5>
          <h3><a href="tel:+1">+1</a></h3>
        </div>
        <div className={"hot-loc anihideVis" + (bView ? " anishowVis" : " ")} style={{ "--i" : "6"}} ref={bRef}>
          <TbMessageStar />
          <h5>Do you have a question?</h5>
          <h3><a href="firstfranklin">firstfranklin@</a></h3>
        </div>
        
      </div>
    </>
  )
}

export default HotLine