import React from 'react'
import { Link } from 'react-router-dom'

import '../styles/succPage.css';
import { FaCheckCircle } from "react-icons/fa";

const SuccessPage = () => {
  return (
    <>
      <div className="SuccPage">
        <div className="succCont">
          <h2>Your transaction is being processed.</h2>
          <p><FaCheckCircle /></p>
          <h4>You'll be notified when it's delivered. contact <Link to={'user/liveChat'}> Admin </Link> for more enquires. Go to <Link to={'user/transactions'}> transactions </Link> page for more details. </h4>
          <div className="succLinks">
            <h3><Link to='user/liveTrade'> Live Trade </Link></h3>
            <h3><Link to={'user/dashboard'}> Dashboard </Link></h3>
          </div>
        </div>
      </div>
    </>
  )
}

export default SuccessPage