import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";


import './App.css';
import Dashboard from "./components/dashboard/templates/Dashboard";
import Home from "./components/home/templates/Home";
import Profile from "./components/dashboard/templates/Profile";
import Card from "./components/dashboard/templates/Card";
import Loan from "./components/dashboard/templates/Loan";
import Index from "./components/dashboard/templates/Index";
import Error from "./components/home/templates/Error";
import Main from "./components/home/templates/home/Main";
import Auth from "./components/auth/templates/Auth"
import Welcome from "./components/home/templates/Welcome";
import Withdrawal from "./components/withdraw/templates/Withdrawal";
import Transfer from "./components/Transfer/templates/Transfer";
import LiveTrading from "./components/livetrade/templates/LiveTrading";
import ChangePassword from "./components/profile/templates/ChangePassword";
import ProfSettings from "./components/profile/templates/ProfSettings";
import Transactions from "./components/transactions/templates/Transactions";
import LiveChat from "./components/livechat/templates/LiveChat";
import SuccessPage from "./components/transactions/templates/SuccessPage";

function App() {

  return (
    <BrowserRouter>
      <Routes>
        <Route exact path="/" element={<Home />} >
          <Route exact path="/" element={<Main />} />
          <Route exact path="sign-in" element={<Auth />} />
          <Route exact path="welcome" element={<Welcome />} />
        </Route>
        <Route exact path="/:user" element={<Index />} >
          <Route exact path="dashboard" element={<Dashboard />} />
          <Route exact path="profile" element={<Profile />} />
          <Route exact path="card" element={<Card />} />
          <Route exact path="transfer" element={<Transfer />} />
          <Route exact path="withdrawal" element={<Withdrawal />} />
          <Route exact path="transactions" element={<Transactions />} />
          <Route exact path="successPage" element={<SuccessPage />} />
          <Route exact path="loan" element={<Loan />} />
          <Route exact path="liveTrade" element={<LiveTrading />} />
          <Route exact path="liveChat" element={<LiveChat />} />
          <Route exact path="profile-setting" element={<ProfSettings />} />
          <Route exact path="change-password" element={<ChangePassword />} />
        </Route>
        <Route path="/*" element={<Error />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
