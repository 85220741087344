import React from 'react'
import moment from 'moment';

const TransDets = ({currentRept, Cancel}) => {
  let USDollar = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });
  
  return (
    <>
      <div className="tsctn-dets " >
        <div className="detailed-trans">
          <div className="dtt">
            <h4>Transaction ID</h4>
            <p className='idt'>FFFC/{currentRept?.option?._id}</p>
          </div>
          <div className="dtt">
            <h4>Date</h4>
            <p className='tID'>{moment(currentRept?.option?.date).format('LLL')}</p>
          </div>
          <div className="dtt">
            <h4>Recipient Name</h4>
            <p className='reptName'>{currentRept?.option?.recName}</p>
          </div>
          <div className="dtt">
            <h4>Recipient Bank</h4>
            <p>{currentRept?.option?.recBank}</p>
          </div>
          <div className="dtt">
            <h4>Account/Routing Number</h4>
            <p>{currentRept?.option?.accNum}</p>
          </div>
          <div className="dtt">
            <h4>Address</h4>
            <p>{currentRept?.option?.address}</p>
          </div>
          <div className="dtt">
            <h4>Amount</h4>
            <p className={currentRept?.option?.alertType === "Debit" ? "red" : "grn"}>{USDollar.format(currentRept?.option?.amount)}</p>
          </div>
          <div className="dtt">
            <h4>Alert Type</h4>
            <p className={ currentRept?.option?.alertType === "Debit" ? "red" : "grn"}>{currentRept?.option?.alertType}</p>
          </div>
          <div className="dtt">
            <h4>Charge</h4>
            <p className='chrg'>{USDollar.format(currentRept?.option?.charge)}</p>
          </div>
          <div className="dtt">
            <h4>Total</h4>
            <p>{USDollar.format(currentRept?.option?.total)}</p>
          </div>
          <div className="dtt">
            <h4>Status</h4>
            <p className={"statz " + currentRept?.option?.status}>{currentRept?.option?.status}</p>
          </div>
          <div className="dtt">
            <h4>Details</h4>
            <p>{currentRept?.option?.details}</p>
          </div>
        </div>
        <h3 className="close" onClick={Cancel}>Close</h3>
      </div>
    </>
  )
}

export default TransDets